@charset "utf-8";
/* inter-100 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-100.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-200 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-200.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-300 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-regular - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-500 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-600 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-700 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-800 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-800.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-900 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-900.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-100 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-100.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-100italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-100italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-200 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-200.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-200italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-200italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-300 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-300italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-300italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-regular - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-500 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-500italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-500italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-600 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-600italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-600italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-700 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-700italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-700italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-800 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-800.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-800italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-800italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-900 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-900.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-900italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-900italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/***
    The new CSS reset - version 1.8.6 (last updated 15.6.2023)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/
/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
    - The "html" element is exclude, because otherwise a bug in Chrome breaks the CSS hyphens property (https://github.com/elad2412/the-new-css-reset/issues/36)
 */
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}
/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}
/* Reapply the pointer cursor for anchor tags */
a,
button {
  cursor: revert;
}
/* Remove list styles (bullets/numbers) */
ol,
ul,
menu {
  list-style: none;
}
/* For images to not be able to exceed their container */
img {
  max-inline-size: 100%;
  max-block-size: 100%;
}
/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}
/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input,
textarea {
  -webkit-user-select: auto;
}
/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}
/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}
/* preformatted text - use only for this feature */
:where(pre) {
  all: revert;
}
/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}
/* remove default dot (•) sign */
::marker {
  content: initial;
}
/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}
/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}
/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
  -webkit-user-drag: element;
}
/* Revert Modal native behavior */
:where(dialog:modal) {
  all: revert;
}
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}
html,
body {
  font-family: Inter;
  text-align: left;
  color: #282828;
}
@media (min-width: 430px) {
  html,
  body {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 429px) {
  html,
  body {
    font-size: 14px;
    line-height: 22px;
  }
}
.heading-common {
  color: #000000;
  font-weight: 700;
  font-style: normal;
}
h1 {
  font-size: 40px;
  line-height: 46px;
  color: #000000;
  font-weight: 700;
  font-style: normal;
}
@media (max-width: 429px) {
  h1 {
    font-size: 32px;
    line-height: 38px;
  }
}
h2 {
  font-size: 32px;
  line-height: 38px;
  color: #000000;
  font-weight: 700;
  font-style: normal;
}
h3 {
  font-size: 28px;
  line-height: 34px;
  color: #000000;
  font-weight: 700;
  font-style: normal;
}
h4 {
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  font-weight: 700;
  font-style: normal;
}
h5 {
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  font-weight: 700;
  font-style: normal;
}
h6 {
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  font-weight: 700;
  font-style: normal;
}
.mark {
  color: #282828;
  font-size: 14px;
  line-height: 2;
}
.underline {
  text-decoration: underline;
  font-size: 16px;
  line-height: 1.75;
  color: #282828;
}
.hint {
  font-size: 12px;
  color: #282828;
}
.bolderline {
  font-size: 14px;
  line-height: 2;
  color: #282828;
  font-weight: 700;
}
.italic {
  font-size: 14px;
  line-height: 2;
  color: #282828;
  font-style: italic;
}
.action-color {
  color: #348cf9;
}
form .form-row-halves {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 429px) {
  form .form-row-halves {
    flex-direction: column;
  }
  form .form-row-halves.mobile-halves {
    flex-direction: row;
  }
  form .form-row-halves.mobile-halves .form-group {
    width: calc(50% - 10px);
  }
}
@media (min-width: 430px) {
  form .form-row-halves .form-group {
    width: calc(50% - 10px);
  }
}
form .form-group {
  margin-bottom: 16px;
}
form .form-group .input-container {
  border-radius: 6px;
}
form .form-group .input-container.multiselect > div {
  width: 100%;
}
form .form-group[validation-state='error'] label {
  color: #dc3545;
}
form .form-group[validation-state='error'] input {
  color: #dc3545;
  border: 1px solid #dc3545;
}
form .form-group[validation-state='error'] input::placeholder {
  color: #dc3545;
}
form .form-group[validation-state='error'] input:focus {
  border: 1px solid #dc3545;
  box-shadow: 0px 0px 0px 3px #f2d2d6;
}
form .form-group[validation-state='error'] .error-msg-input {
  margin-top: 8px;
  color: #dc3545;
  font-size: 12px;
  line-height: 12px;
}
@media (max-width: 429px) {
  form .form-group[validation-state='error'] .error-msg-input {
    font-size: 16px;
    line-height: 18px;
  }
}
form .form-group[validation-state='error'] .input-error-group .error-msg-input {
  margin-left: 16px;
  margin-top: 0;
  font-size: 12px;
  line-height: 20px;
}
form .form-group label {
  font-size: 12px;
  line-height: 20px;
  padding-left: 16px;
  color: #000000;
}
form .form-group label.no-padding {
  padding-left: 0;
}
form .form-group label.field-tos {
  display: flex;
  align-items: flex-start;
}
form .form-group label.field-tos a {
  text-decoration: underline;
}
@media (max-width: 429px) {
  form .form-group label.checkbox-font {
    font-size: 14px;
    line-height: 18px;
  }
}
form .form-group .error-msg-checkbox {
  margin-top: 8px;
  color: #dc3545;
  font-size: 12px;
  line-height: 12px;
}
@media (max-width: 429px) {
  form .form-group .error-msg-checkbox {
    font-size: 14px;
    line-height: 18px;
  }
}
form .form-group .checkbox-container.invalid-checkbox {
  border-radius: 4px;
  border: 1px solid #dc3545;
  padding: 8px 0;
}
form .form-group .input-container {
  display: flex;
  align-items: center;
}
form input.tos {
  width: 16px;
  height: 16px;
  margin: 8px;
  flex-shrink: 0;
}
form .recaptcha {
  margin-bottom: 16px;
}
form .error {
  color: #dc3545;
  padding-left: 20px;
  background: url('../public/icons/x-circle.svg') no-repeat left center;
  margin-top: 8px;
  text-align: left;
}
form .success {
  color: #28a745;
  padding-left: 20px;
  background: url('../public/icons/check-circle-green.svg') no-repeat left center;
  margin-top: 8px;
  text-align: left;
}
.form-group[validation-state='error'] label {
  color: #dc3545;
}
.form-group[validation-state='error'] textarea,
.form-group[validation-state='error'] input,
.form-group[validation-state='error'] select {
  color: #dc3545;
  border: 1px solid #dc3545;
}
.form-group[validation-state='error'] textarea::placeholder,
.form-group[validation-state='error'] input::placeholder,
.form-group[validation-state='error'] select::placeholder {
  color: #dc3545;
}
.form-group[validation-state='error'] textarea:focus,
.form-group[validation-state='error'] input:focus,
.form-group[validation-state='error'] select:focus {
  border: 1px solid #dc3545;
  box-shadow: 0px 0px 0px 3px #f2d2d6;
}
.form-group[validation-state='error'] .error-msg-input {
  margin-top: 8px;
  color: #dc3545;
  font-size: 12px;
  line-height: 12px;
}
@media (max-width: 429px) {
  .form-group[validation-state='error'] .error-msg-input {
    font-size: 16px;
    line-height: 18px;
  }
}
.form-group[validation-state='error'] .input-error-group .error-msg-input {
  margin-left: 16px;
  margin-top: 0;
  font-size: 12px;
  line-height: 20px;
}
.form-group[validation-state='error'] .input-error-group .icon-select-downarrow {
  background-image: url('../public/icons/selectarrow-red.svg');
}
.form-group[validation-state='error'] .error-msg-checkbox {
  margin-top: 8px;
  color: #dc3545;
  font-size: 12px;
  line-height: 12px;
}
@media (max-width: 429px) {
  .form-group[validation-state='error'] .error-msg-checkbox {
    font-size: 14px;
    line-height: 18px;
  }
}
.form-group[validation-state='error'] .checkbox-container.invalid-checkbox {
  border-radius: 4px;
  border: 1px solid #dc3545;
  padding: 8px 0;
}
input[type='checkbox'] {
  all: revert;
}
.btn {
  padding: 10px 0;
  width: 100%;
  border-radius: 6px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
}
.btn.btn-primary {
  background-color: #348cf9;
  color: #ffffff;
}
.btn.btn-primary:hover:not([disabled]) {
  background-color: #0064df;
}
.btn.btn-outline {
  border: 1px solid #000000;
  background-color: #ffffff;
}
.btn.btn-outline:hover:not([disabled]) {
  background-color: #000000;
  color: #ffffff;
}
.btn.btn-danger {
  background-color: #dc3545;
  color: #ffffff;
}
.btn.btn-outline-light {
  border-radius: 4px;
  border: 1px solid #dee2e6;
  background-color: #ffffff;
  padding: 12px 14px;
  font-weight: 400;
}
.btn.btn-transparent {
  border: 1px solid #000000;
  background-color: transparent;
}
.btn.btn-transparent:hover:not([disabled]) {
  background-color: #000000;
  color: #ffffff;
}
.btn.btn-next {
  border: 1px solid #dee2e6;
  background: url('../public/icons/arrow-right.svg') no-repeat calc(100% - 20px) center;
}
.btn.btn-link {
  color: #348cf9;
  font-weight: 400;
  padding: 10px;
}
.btn.btn-link:hover:not([disabled]) {
  text-decoration: underline;
}
.btn.btn-link.disabled,
.btn.btn-link:disabled {
  color: #76b3ff;
}
.btn.btn-link.disabled:hover,
.btn.btn-link:disabled:hover {
  text-decoration: none;
}
.btn.btn-link-light {
  color: #348cf9;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  padding: 3px 18px 3px 0;
  width: fit-content;
}
.btn.btn-link-light:hover:not([disabled]) {
  text-decoration: underline;
}
.btn.btn-link-light.disabled,
.btn.btn-link-light:disabled {
  color: #76b3ff;
}
.btn.btn-link-light.disabled:hover,
.btn.btn-link-light:disabled:hover {
  text-decoration: none;
}
.btn.btn-block {
  display: block;
}
.btn.btn-short {
  padding: 10px;
  width: auto;
  min-width: 101px;
  height: 38px;
}
.btn.btn-col-1 {
  display: block;
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
}
.btn.btn-col-2 {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
}
@media (max-width: 429px) {
  .btn.btn-col-2 {
    width: calc(calc((calc(100vw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
}
.btn:disabled {
  background-color: #adb5bd;
}
.btn.disable-fade-primary {
  background-color: #348cf9;
}
.btn.disable-fade-primary:disabled {
  opacity: 0.5;
}
.btn.disable-fade-transparent {
  background-color: transparent;
}
.btn.disable-fade-transparent:disabled {
  opacity: 0.5;
}
.link {
  color: #348cf9;
  font-size: 12px;
  line-height: 12px;
}
.link:hover:not([disabled]) {
  text-decoration: underline;
}
.link.disabled,
.link:disabled {
  color: #76b3ff;
}
.link.disabled:hover,
.link:disabled:hover {
  text-decoration: none;
}
input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'],
textarea,
select {
  width: 100%;
  height: 38px;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 7px 16px;
}
input[type='text'] option,
input[type='number'] option,
input[type='password'] option,
input[type='email'] option,
textarea option,
select option {
  color: #000000;
}
input[type='text'].empty,
input[type='number'].empty,
input[type='password'].empty,
input[type='email'].empty,
textarea.empty,
select.empty,
input[type='text']::placeholder,
input[type='number']::placeholder,
input[type='password']::placeholder,
input[type='email']::placeholder,
textarea::placeholder,
select::placeholder {
  color: #adb5bd;
  font-weight: 400;
}
input[type='text']:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='email']:focus,
textarea:focus,
select:focus {
  border: 1px solid #000000;
  box-shadow: 0px 0px 0px 3px #b7b7b799;
}
input[type='text']:focus::placeholder,
input[type='number']:focus::placeholder,
input[type='password']:focus::placeholder,
input[type='email']:focus::placeholder,
textarea:focus::placeholder,
select:focus::placeholder {
  color: #000000;
}
input[type='text']:disabled,
input[type='number']:disabled,
input[type='password']:disabled,
input[type='email']:disabled,
textarea:disabled,
select:disabled {
  border-color: #d1d2d2;
  background-color: #e9ecef;
}
textarea.form-control {
  height: 114px;
}
input[type='password'].showable-password {
  padding-right: 32px;
}
.show-password-btn {
  margin-left: -32px;
  width: 16px;
  height: 16px;
}
.show-password-btn.password-visible {
  background: url('../public/icons/eye-fill.svg') no-repeat center;
}
.show-password-btn.password-hidden {
  background: url('../public/icons/eye-empty.svg') no-repeat center;
}
@media (min-width: 430px) {
  .form-row-layout .row {
    padding: 48px calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    border-bottom: 0.5px solid #adb5bd;
    display: flex;
    gap: 20px;
  }
  .form-row-layout .row:last-of-type {
    border-bottom: none;
  }
  .form-row-layout .row .col {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 5 + 20px * 4);
  }
  .form-row-layout .row .col .form-group {
    display: flex;
    gap: 20px;
    align-items: flex-start;
  }
  .form-row-layout .row .col .form-group label {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
  }
  .form-row-layout .row .col .form-group label span {
    display: block;
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
  }
  .form-row-layout .row .col .form-group .input-error-group {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
  }
  .form-row-layout .row .col .form-group .input-error-group .input-container {
    background-color: #ffffff;
  }
  .form-row-layout .row .col .form-group.contains-textarea {
    align-items: flex-start;
  }
  .form-row-layout .row .col .form-group.contains-textarea .input-container {
    background: none;
  }
  .form-row-layout .row .col .form-group.contains-textarea .input-container textarea {
    background-color: #ffffff;
  }
  .form-row-layout .row .col.range-field {
    display: flex;
    align-items: center;
  }
  .form-row-layout .row .col.range-field .input-error-group {
    width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  }
  .form-row-layout .row .col.range-field .separator {
    width: 10px;
    height: 2px;
    background-color: #000000;
    margin: 0 5px;
  }
  .form-row-layout .row .col.range-field label span {
    margin-top: 5px;
  }
}
@media (min-width: 430px) and (min-width: 430px) {
  .form-row-layout .row .col.range-field {
    flex-wrap: wrap;
  }
}
@media (min-width: 430px) and (min-width: 430px) {
  .form-row-layout .row .col.range-field .range-error-msg-input {
    flex-basis: 100%;
    padding-left: calc(calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px) + 20px + 18px);
  }
}
@media (max-width: 429px) {
  .form-row-layout .row .col {
    margin-top: 20px;
  }
  .form-row-layout .row .col .form-group {
    margin-bottom: 20px;
  }
  .form-row-layout .row .col .form-group label {
    padding-left: 0;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
  }
  .form-row-layout .row .col .form-group label span {
    display: block;
    margin-top: 8px;
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 400;
  }
  .form-row-layout .row .col.range-field {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr 16px;
    grid-column-gap: 0px;
    grid-row-gap: 12px;
    grid-template-areas: 'label label label' 'min separator max' 'err err err';
  }
  .form-row-layout .row .col.range-field label {
    grid-area: label;
  }
  .form-row-layout .row .col.range-field label span {
    margin-bottom: 0;
  }
  .form-row-layout .row .col.range-field .form-group {
    display: contents;
  }
  .form-row-layout .row .col.range-field .input-error-group {
    width: calc(calc((calc(100vw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
  .form-row-layout .row .col.range-field .form-group:first-of-type .input-error-group {
    grid-area: min;
  }
  .form-row-layout .row .col.range-field .form-group:last-of-type .input-error-group {
    grid-area: max;
  }
  .form-row-layout .row .col.range-field .range-error-msg-input {
    grid-area: err;
    padding: 0;
  }
  .form-row-layout .row .col.range-field .separator {
    grid-area: separator;
    width: 8px;
    height: 2px;
    background-color: #000000;
    margin: 18px 2px;
  }
}
.input-container.icon-select-downarrow {
  background: url('../public/icons/select-downarrow.svg') #ffffff no-repeat calc(100% - 16px) center;
  background-size: 16px;
}
.input-container.icon-select-downarrow select:disabled {
  background: url('../public/icons/select-downarrow.svg') #e9ecef no-repeat calc(100% - 16px) center;
}
.input-container.icon-select-downarrow input:disabled {
  background: url('../public/icons/select-downarrow.svg') #e9ecef no-repeat right center;
}
.multiselect-container {
  border-radius: 6px;
}
.multiselect-container .searchWrapper {
  height: 38px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  border-radius: 6px;
  border: 1px solid #dee2e6;
}
.multiselect-container .searchWrapper .chip {
  margin-bottom: 0;
  padding: 4px 17px 4px 7px;
}
@media (max-width: 429px) {
  .multiselect-container .searchWrapper .chip {
    padding-right: 16px;
  }
}
.multiselect-container input[type='text'] {
  margin-top: 0;
  padding-left: 0;
}
@media (max-width: 429px) {
  .multiselect-container input[type='text'] {
    width: 100%;
  }
}
.multiselect-container input[type='text']:focus {
  border: none;
  box-shadow: none;
}
.multiselect-container input[type='text']:focus::placeholder {
  color: #000000;
}
.multiselect-container.disable_ms {
  background-color: #e9ecef;
  opacity: 1;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.range-field .range-error-msg-input {
  padding-left: 16px;
  font-size: 12px;
  line-height: 20px;
  color: #dc3545;
}
@media (max-width: 429px) {
  .col .form-group label {
    padding-left: 16px;
    font-size: 12px;
    line-height: 12px;
  }
}
header {
  height: 80px;
  padding: 0 40px;
}
@media (max-width: 429px) {
  header {
    height: 52px;
    padding: 0 24px;
  }
}
header .container {
  height: 100%;
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 10 + 20px * 9);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 429px) {
  header .container {
    width: 100%;
  }
  header .container .logo {
    position: relative;
    top: 5px;
  }
  header .container .logo a {
    display: block;
    height: 23px;
  }
}
header .mobile-menu {
  display: none;
}
@media (max-width: 429px) {
  header .mobile-menu {
    display: block;
    width: 35px;
    height: 35px;
    background: url('../public/icons/hamburger.svg') no-repeat center;
  }
  header .mobile-menu.opened {
    background: url('../public/icons/hamburger-close.svg') no-repeat center;
  }
}
header .desktop-menu-btn {
  width: 32px;
  height: 32px;
  padding: 4px;
  background: url('../public/icons/person.svg') no-repeat center;
  cursor: pointer;
}
@media (max-width: 429px) {
  header .desktop-menu-btn {
    display: none;
  }
}
header .desktop-menu-btn.logged-out {
  display: none;
}
header .desktop-menu-btn.opened {
  background-color: #e9ecef;
  border-radius: 4px;
  border: 1px solid #e9ecef;
  position: relative;
}
header .desktop-menu-btn.opened .menu-container {
  display: block;
  z-index: 2;
}
header .desktop-menu-btn .menu-container {
  display: none;
  position: absolute;
  right: 0;
  top: 36px;
  min-width: 137px;
  box-shadow: 0px 5px 20px 0px #00000040;
  background-color: #ffffff;
  border-radius: 4px;
}
header .desktop-menu-btn .menu-container .links {
  width: 100%;
}
header .desktop-menu-btn .menu-container .links a {
  display: block;
  padding: 12px;
  white-space: nowrap;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}
header .desktop-menu-btn .menu-container .links a:hover {
  color: #348cf9;
  font-weight: 600;
}
header .desktop-menu-btn .menu-container .links .separator {
  height: 1px;
  background-color: #7b7c7c;
  margin: 0 12px;
}
header .notification {
  padding: 16px;
  border-radius: 6px;
  top: 0;
  position: relative;
}
header .notification.status-success {
  border: 1px solid #28a745;
  background-color: #c1edd9;
}
header .notification.status-error {
  border: 1px solid #dc3545;
  background-color: #f2d2d6;
}
@media (max-width: 429px) {
  header .notification {
    position: fixed;
    width: calc(100vw - 2 * 24px);
    top: 10px;
  }
}
header .notification.fadeout {
  top: -200px;
  transition: top 0.5s ease-in-out;
}
.app-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.app-wrapper header {
  flex-shrink: 0;
}
.app-wrapper .app-body {
  flex-grow: 1;
  overflow: auto;
  min-height: 2em;
}
@media (min-width: 430px) {
  .app-wrapper .app-body.signup-layout-initial-form {
    overflow: revert;
    min-height: fit-content;
  }
}
.app-wrapper .app-body.hidden {
  display: none;
}
.app-wrapper footer {
  flex-shrink: 0;
}
footer {
  background-color: #000000;
  color: #ffffff;
  display: flex;
  align-items: center;
}
@media (min-width: 430px) {
  footer {
    height: 80px;
    padding: 0 40px;
  }
}
@media (max-width: 429px) {
  footer.logged-out {
    height: 44px;
  }
  footer.logged-out .container .logo {
    display: none;
  }
  footer.logged-out .container .links a {
    display: none;
  }
  footer.logged-out .container .links .copyright {
    padding: 0;
  }
  footer.logged-in {
    height: 220px;
  }
  footer.hidden {
    display: none;
  }
}
footer .container {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 430px) {
  footer .container {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 10 + 20px * 9);
    margin: 0 auto;
    align-items: center;
  }
}
@media (max-width: 429px) {
  footer .container {
    padding: 16px 24px;
    height: 100%;
    width: 100%;
    margin: 0;
    flex-direction: column;
    gap: 30px;
  }
  footer .container .links {
    flex-direction: column;
    gap: 16px;
    line-height: 12px;
  }
  footer .container .links .copyright {
    text-align: center;
    padding-top: 14px;
  }
}
footer .logo {
  font-family: Montserrat;
  font-size: 20px;
}
footer .logo strong {
  font-weight: 700;
}
footer .links {
  display: flex;
  gap: 40px;
  font-size: 12px;
}
footer .links a,
footer .links a:visited {
  color: #ffffff;
}
footer .links a:hover:not([disabled]),
footer .links a:visited:hover:not([disabled]) {
  text-decoration: underline;
}
footer .links a .disabled,
footer .links a:visited .disabled,
footer .links a:disabled,
footer .links a:visited:disabled {
  color: #76b3ff;
}
html {
  padding: 0;
}
html body .header-wrapper {
  background-color: #ffffff;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  border: 0.5rem solid #ebe6e6;
}
html body .header-wrapper a {
  font-size: 1.5rem;
  margin-right: 1rem;
}
html body .footer-wrapper {
  background-color: #ffffff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border: 0.5rem solid #e04c2b;
  justify-content: center;
  align-items: center;
}
html body .login-wrapper {
  margin-top: 134px;
}
@media (max-width: 429px) {
  html body .login-wrapper {
    margin-top: 0;
  }
}
html body .login-wrapper .link {
  display: block;
  margin: 16px 0 24px 16px;
}
@media (max-width: 429px) {
  html body .login-wrapper .link {
    margin: 16px 0 24px 0;
  }
}
html body .investor-dashboard-wrapper {
  background-color: #ffffff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border: 0.5rem solid #ace6a8;
  justify-content: center;
  align-items: center;
}
html body .counter-wrapper {
  background-color: #ffffff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border: 0.5rem solid #ace6a8;
  justify-content: center;
  align-items: center;
}
.signup-layout-initial-form {
  display: flex;
  gap: 20px;
  padding: 0 40px;
  height: 734px;
  justify-content: center;
  margin-bottom: 51px;
}
@media (max-width: 429px) {
  .signup-layout-initial-form {
    display: block;
    padding: 24px 24px 0;
    max-height: calc(100vh - 150px);
  }
}
@media (min-width: 430px) {
  .signup-layout-initial-form .left-item {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 6 + 20px * 5);
    display: flex;
    align-items: center;
  }
}
@media (min-width: 430px) {
  .signup-layout-initial-form .left-item h1 {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 5 + 20px * 4);
    margin-bottom: 48px;
    font-size: 64px;
    line-height: 70px;
  }
}
@media (max-width: 429px) {
  .signup-layout-initial-form .left-item h1 {
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 38px;
  }
}
.signup-layout-initial-form .left-item h2 {
  color: #348cf9;
}
@media (min-width: 430px) {
  .signup-layout-initial-form .left-item h2 {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 3 + 20px * 2);
    font-size: 32px;
    line-height: 38px;
  }
}
@media (max-width: 429px) {
  .signup-layout-initial-form .left-item h2 {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 40px;
  }
}
@media (max-width: 429px) {
  .signup-layout-initial-form .left-item img {
    display: none;
  }
}
.signup-layout-initial-form .middle-item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
}
.signup-layout-initial-form .middle-item .divider {
  height: 100%;
  width: 1px;
  margin: 0 auto;
  border: 0.5px solid #adb5bd;
}
@media (max-width: 429px) {
  .signup-layout-initial-form .middle-item {
    display: none;
  }
}
@media (min-width: 430px) {
  .signup-layout-initial-form .right-item {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 3 + 20px * 2);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .signup-layout-initial-form .right-item .mandatory-text-mobile {
    display: none;
  }
}
@media (max-width: 429px) {
  .signup-layout-initial-form .right-item .mandatory-text-desktop {
    display: none;
  }
  .signup-layout-initial-form .right-item .mandatory-text-mobile {
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 20px;
  }
}
.signup-layout-initial-form .right-item .mandatory-text-desktop {
  font-size: 12px;
  line-height: 20px;
  margin-top: 16px;
}
.page-login-layout-forgotten-password,
.page-signup-layout-confirm-code {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
}
@media (max-width: 429px) {
  .page-login-layout-forgotten-password,
  .page-signup-layout-confirm-code {
    display: flex;
    align-items: center;
    padding: 0 24px;
    height: calc(100vh - 111px);
  }
}
.page-login-layout-forgotten-password form,
.page-signup-layout-confirm-code form {
  width: 584px;
  box-shadow: 0px 10px 40px 0px #00000026;
  padding: 60px;
  text-align: center;
}
@media (max-width: 429px) {
  .page-login-layout-forgotten-password form,
  .page-signup-layout-confirm-code form {
    box-shadow: none;
    padding: 0;
    height: 312px;
    width: fit-content;
  }
}
.page-login-layout-forgotten-password form h4,
.page-signup-layout-confirm-code form h4 {
  margin-bottom: 24px;
  text-align: center;
}
@media (max-width: 429px) {
  .page-login-layout-forgotten-password form h4,
  .page-signup-layout-confirm-code form h4 {
    line-height: 24px;
  }
}
.page-login-layout-forgotten-password form .check-text,
.page-signup-layout-confirm-code form .check-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  color: #000000;
  font-weight: 500;
}
@media (max-width: 429px) {
  .page-login-layout-forgotten-password form .check-text,
  .page-signup-layout-confirm-code form .check-text {
    margin-bottom: 48px;
  }
}
.page-login-layout-forgotten-password form .check-text .icon-check-circle,
.page-signup-layout-confirm-code form .check-text .icon-check-circle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background: url('../public/icons/check-circle.svg') no-repeat center;
}
.page-login-layout-forgotten-password form .form-group,
.page-signup-layout-confirm-code form .form-group {
  text-align: left;
  margin-bottom: 12px;
}
@media (max-width: 429px) {
  .page-login-layout-forgotten-password form .form-group,
  .page-signup-layout-confirm-code form .form-group {
    text-align: left;
  }
}
.page-login-layout-forgotten-password form .form-group label,
.page-signup-layout-confirm-code form .form-group label {
  display: block;
  padding-bottom: 8px;
}
.page-login-layout-forgotten-password form .form-group .verify-input,
.page-signup-layout-confirm-code form .form-group .verify-input {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}
.page-login-layout-forgotten-password form .form-group .verify-input::placeholder,
.page-signup-layout-confirm-code form .form-group .verify-input::placeholder {
  text-align: left;
}
@media (max-width: 429px) {
  .page-login-layout-forgotten-password form .form-group .verify-input,
  .page-signup-layout-confirm-code form .form-group .verify-input {
    font-size: 16px;
  }
}
.page-login-layout-forgotten-password form .subtext,
.page-signup-layout-confirm-code form .subtext {
  font-size: 10px;
  line-height: 12px;
  text-align: center;
}
@media (max-width: 429px) {
  .page-login-layout-forgotten-password form .subtext,
  .page-signup-layout-confirm-code form .subtext {
    margin-bottom: 16px;
  }
}
.page-login-layout-forgotten-password form .resend,
.page-signup-layout-confirm-code form .resend {
  text-align: center;
}
.page-login-layout-forgotten-password form .resend a,
.page-signup-layout-confirm-code form .resend a,
.page-login-layout-forgotten-password form .resend a:visited,
.page-signup-layout-confirm-code form .resend a:visited {
  color: #000000;
  text-decoration: underline;
  font-size: 12px;
  line-height: 12px;
}
.page-login-layout-forgotten-password form .btn,
.page-signup-layout-confirm-code form .btn {
  width: 222px;
  margin-top: 32px;
}
@media (min-width: 430px) {
  .page-login-layout-forgotten-password form .btn,
  .page-signup-layout-confirm-code form .btn {
    display: inline-block;
  }
}
@media (max-width: 429px) {
  .page-login-layout-forgotten-password form .btn,
  .page-signup-layout-confirm-code form .btn {
    width: 100%;
    margin-bottom: 12px;
  }
}
@media (min-width: 430px) {
  .login-layout-forgotten-password h4,
  .login-layout-forgotten-password .check-text {
    width: 369px;
    margin: 0 auto;
  }
}
.login-layout-forgotten-password form.step-3 {
  width: 593px;
  padding: 60px 32px;
}
.login-layout-forgotten-password form.step-3 h4 {
  line-height: 24px;
  width: fit-content;
}
@media (max-width: 429px) {
  .login-layout-forgotten-password form.step-3 h4 {
    line-height: 30px;
    margin-bottom: 32px;
  }
}
.login-layout-forgotten-password form.step-3 .check-text {
  line-height: 16px;
}
.login-layout-forgotten-password form.step-3 .btn {
  margin: 32px auto 0;
}
.app-body.page-signup-layout-success {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 429px) {
  .app-body.page-signup-layout-success {
    padding: 0 24px;
    height: calc(100vh - 211px);
  }
}
@media (min-width: 430px) {
  .app-body.page-signup-layout-success h1 {
    font-size: 48px;
    line-height: 54px;
    margin-bottom: 40px;
  }
}
@media (max-width: 429px) {
  .app-body.page-signup-layout-success h1 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 32px;
  }
}
.app-body.page-signup-layout-success h2 {
  font-weight: 400;
}
@media (min-width: 430px) {
  .app-body.page-signup-layout-success h2 {
    font-size: 24px;
    line-height: 24px;
  }
}
@media (max-width: 429px) {
  .app-body.page-signup-layout-success h2 {
    font-size: 20px;
    line-height: 26px;
  }
}
.page-login-layout-verification-progress.app-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-login-layout-verification-progress.app-body .container-verification {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 8 + 20px * 7);
  padding: 60px 121px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
}
@media (max-width: 429px) {
  .page-login-layout-verification-progress.app-body .container-verification {
    width: 100%;
    margin: 112px auto;
    padding: 0 24px;
    box-shadow: none;
  }
}
.page-login-layout-verification-progress.app-body .container-verification .verification-header {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 4 + 20px * 3);
  margin: 0 auto;
}
@media (max-width: 429px) {
  .page-login-layout-verification-progress.app-body .container-verification .verification-header {
    width: 100%;
  }
}
.page-login-layout-verification-progress.app-body .container-verification h4 {
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
}
@media (max-width: 429px) {
  .page-login-layout-verification-progress.app-body .container-verification h4 {
    line-height: 30px;
    margin-bottom: 32px;
  }
}
.page-login-layout-verification-progress.app-body .container-verification h6 {
  margin-bottom: 48px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}
@media (max-width: 429px) {
  .page-login-layout-verification-progress.app-body .container-verification h6 {
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 20px;
  }
}
.page-login-layout-verification-progress.app-body .container-verification .steps {
  display: flex;
  gap: 20px;
}
@media (max-width: 429px) {
  .page-login-layout-verification-progress.app-body .container-verification .steps {
    flex-direction: column;
    width: 176px;
    margin: 0 auto;
  }
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
}
@media (max-width: 429px) {
  .page-login-layout-verification-progress.app-body .container-verification .steps .step {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step .visual {
  width: 100%;
  height: 8px;
  border-radius: 12px;
  margin-bottom: 12px;
}
@media (max-width: 429px) {
  .page-login-layout-verification-progress.app-body .container-verification .steps .step .visual {
    height: 100%;
    width: 8px;
    margin: 0;
  }
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step .text {
  width: fit-content;
  margin: 0 auto;
  height: 24px;
  padding-left: 36px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  background-repeat: no-repeat;
}
@media (max-width: 429px) {
  .page-login-layout-verification-progress.app-body .container-verification .steps .step .text {
    margin: 0;
  }
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step.complete .visual {
  background: #348cf9;
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step.complete .text {
  color: #000000;
  background-image: url('../public/icons/check-circle-blue.svg');
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step.awaiting .visual {
  background: #dee2e6;
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step.awaiting .text {
  color: #000000;
  background-image: url('../public/icons/loading-black.svg');
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step.unavailable .visual {
  background: #dee2e6;
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step.unavailable .text {
  color: #adb5bd;
  background-image: url('../public/icons/loading-gray.svg');
}
.page-login-layout-verification-progress.app-body .container-verification .info {
  margin-top: 32px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}
@media (max-width: 429px) {
  .page-login-layout-verification-progress.app-body .container-verification .info {
    margin-top: 32px;
  }
}
.fake-btn {
  cursor: pointer;
  padding: 4px;
  border: 1px solid black;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
}
.linkedin-wrapper {
  padding: 6px;
  border: 1px solid black;
  border-radius: 4px;
  margin-top: 6px;
}
.linkedin-wrapper a {
  width: 100%;
  display: block;
  text-align: center;
}
.linkedin-wrapper a:hover {
  text-decoration: none;
}
.typography-demo .section-divider {
  border-bottom: 1px solid black;
}
.typography-demo .colors-row .color-cell {
  display: inline-block;
  width: 170px;
  height: 60px;
  margin-right: 20px;
}
.typography-demo .colors-row .color-cell .color-box {
  width: 100%;
  height: 100%;
  border: 1px solid black;
  border-radius: 4px;
}
.typography-demo .colors-row .color-cell .color-box.primary-black {
  background-color: #000000;
}
.typography-demo .colors-row .color-cell .color-box.action-blue {
  background-color: #348cf9;
}
.typography-demo .colors-row .color-cell .color-box.gray600 {
  background-color: #7b7c7c;
}
.typography-demo .colors-row .color-cell .color-box.gray500 {
  background-color: #adb5bd;
}
.typography-demo .colors-row .color-cell .color-box.gray400 {
  background-color: #d1d2d2;
}
.typography-demo .colors-row .color-cell .color-box.gray300 {
  background-color: #dee2e6;
}
.typography-demo .colors-row .color-cell .color-box.error {
  background-color: #dc3545;
}
.typography-demo .colors-row .color-cell .color-box.success {
  background-color: #28a745;
}
.typography-demo .colors-row .color-cell .color-box.warning {
  background-color: #ffd041;
}
.typography-demo .colors-row .color-cell .color-box.info {
  background-color: #28bbfa;
}
.typography-demo .colors-row .color-cell .color-box.pink100 {
  background-color: #f2d2d6;
}
nav {
  display: none;
}
nav.opened {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 68px;
  width: 100%;
  height: calc(100% - 68px);
  background: #ffffff;
}
nav.opened ul {
  width: 100%;
  display: flex;
  flex-direction: column;
}
nav.opened ul li {
  height: 48px;
  display: block;
  width: 100%;
  padding: 0 24px;
  background: url('../public/icons/arrow-right.svg') no-repeat calc(100% - 30px) center;
}
nav.opened ul li.hidden {
  display: none;
}
nav.opened ul li a,
nav.opened ul li a:visited {
  font-size: 16px;
  line-height: 48px;
  font-weight: 600;
  display: block;
  width: 100%;
  height: 100;
}
nav.opened ul li.selected {
  color: #ffffff;
  background: url('../public/icons/arrow-right-white.svg') #348cf9 no-repeat calc(100% - 30px) center;
}
nav.opened .logout a {
  display: block;
  margin: 0 0 40px 24px;
  padding-left: 32px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  background: url('../public/icons/box-arrow-right.svg') no-repeat left center;
}
nav.opened .confirm-logout {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 24px;
  gap: 40px;
}
nav.opened .confirm-logout .text {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}
nav.opened .confirm-logout .actions {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
nav.opened .confirm-logout .actions a {
  display: block;
}
.mobile-footer {
  flex-shrink: 0;
  width: 100%;
  height: 44px;
  color: #ffffff;
  background-color: #000000;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 430px) {
  .mobile-footer {
    display: none;
  }
}
.text-divider {
  display: flex;
  align-items: center;
  gap: 13px;
  margin: 24px 0;
}
.text-divider::before,
.text-divider::after {
  content: '';
  height: 1px;
  background-color: silver;
  flex-grow: 1;
}
.nav-footer-waitlist {
  display: none;
}
@media (max-width: 429px) {
  .only-desktop {
    display: none;
  }
}
@media (min-width: 430px) {
  .only-mobile {
    display: none;
  }
}
.loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.loader-wrapper .content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.static-container {
  margin: 0 40px;
  padding: 0 calc(20px + calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12));
}
@media (max-width: 429px) {
  .static-container {
    margin: 0;
    padding: 0 24px;
  }
}
.static-container h1 {
  margin: 44px 0 48px 0;
  font-size: 40px;
  line-height: 40px;
}
@media (max-width: 429px) {
  .static-container h1 {
    margin: 32px 0;
    font-size: 32px;
    line-height: 32px;
  }
}
.static-container p {
  max-width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 7 + 20px * 6);
  margin-bottom: 24px;
}
@media (max-width: 429px) {
  .static-container p {
    max-width: 100%;
  }
}
.static-container p strong {
  font-weight: 700;
}
@media (min-width: 430px) {
  .static-container.page-tos {
    padding-right: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 4 + 20px * 3);
  }
}
.static-container.page-tos h1 {
  margin: 40px 0 48px 0;
}
@media (max-width: 429px) {
  .static-container.page-tos h1 {
    margin: 32px 0;
  }
}
.static-container.page-tos .content-header {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 24px;
}
.static-container.page-tos .content-body {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 24px;
}
.static-container.page-tos .content-body strong {
  font-weight: 700;
}
.static-container.page-tos .content-body p {
  margin: 0;
}
.static-container.page-tos .content-body p.section {
  margin: 24px 0 12px;
}
.static-container.page-tos .content-body .content-large {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}
.static-container.page-tos ul {
  list-style: disc;
  list-style-position: outside;
  padding-left: 20px;
}
.static-container.page-tos a {
  text-decoration: underline;
  margin: 0 4px;
}
.static-container.page-tos .content-footer {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 48px;
}
@media (max-width: 429px) {
  .static-container.page-tos .content-footer {
    margin-bottom: 32px;
  }
}
.static-container.page-tos ol {
  list-style: decimal;
  list-style-position: outside;
  padding-left: 20px;
}
@media (max-width: 429px) {
  .signup-layout-initial-form .form-footer {
    bottom: 0;
    position: fixed;
    width: 100vw;
    margin-left: -24px;
    padding: 24px;
    box-shadow: 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
    background: #ffffff;
    z-index: 2;
  }
  .signup-layout-initial-form .form-footer .mandatory-text-mobile {
    display: none;
  }
  .signup-layout-initial-form .form-footer.mobile-hidden {
    display: none;
  }
}
@media (max-width: 429px) {
  .app-body.page-company-profile {
    overflow: auto;
    height: calc(100vh - 140px);
    flex-grow: 0;
  }
}
.app-body.page-company-profile .form-header {
  background-color: #f4f5f7;
  border-radius: 16px;
  padding: 36px calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  margin-bottom: 20px;
}
@media (max-width: 429px) {
  .app-body.page-company-profile .form-header {
    border-radius: 4px;
    padding: 16px;
  }
}
.app-body.page-company-profile .form-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-body.page-company-profile .form-header .title {
  color: #000000;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 12px;
}
@media (max-width: 429px) {
  .app-body.page-company-profile .form-header .title {
    font-size: 32px;
    line-height: 32px;
  }
}
.app-body.page-company-profile .form-header .subtitle {
  color: #000000;
  font-size: 14px;
  line-height: 14px;
}
.app-body.page-company-profile .form-header .actions {
  display: flex;
  gap: 20px;
}
@media (max-width: 429px) {
  .app-body.page-company-profile .form-header .actions {
    display: none;
  }
}
.app-body.page-company-profile .form-body {
  background-color: #f4f5f7;
  border-radius: 16px;
}
@media (max-width: 429px) {
  .app-body.page-company-profile .form-body {
    background-color: #ffffff;
    border-radius: 0;
    padding: 0;
  }
}
.app-body.page-company-profile .form-body label {
  padding: 0;
}
.app-body.page-company-profile .form-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 24px;
  background: #ffffff;
  display: flex;
  gap: 12px;
  box-shadow: 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
}
@media (min-width: 430px) {
  .app-body.page-company-profile .form-footer {
    display: none;
  }
}
.app-body.page-company-profile .progress-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (min-width: 430px) {
  .app-body.page-company-profile .progress-container {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 4 + 20px * 3);
    position: fixed;
    top: 36px;
    left: 50vw;
    transform: translateX(-50%);
  }
}
.app-body.page-company-profile .progress-container .bar-container {
  display: flex;
}
.app-body.page-company-profile .progress-container .bar-container .bar {
  width: 50%;
  height: 4px;
}
.app-body.page-company-profile .progress-container .bar-container .bar:first-of-type {
  border-radius: 12px 0 0 12px;
}
.app-body.page-company-profile .progress-container .bar-container .bar:last-of-type {
  border-radius: 0 12px 12px 0;
}
.app-body.page-company-profile .progress-container .bar-container .bar.blue {
  background-color: #348cf9;
}
.app-body.page-company-profile .progress-container .bar-container .bar.gray {
  background-color: #dee2e6;
}
.app-body.page-company-profile .progress-container .icon-text {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
}
.app-body.page-company-profile .overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
.app-body.page-company-profile .overlay .container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
}
@media (max-width: 429px) {
  .app-body.page-company-profile .overlay .container {
    margin: 0 24px;
  }
}
@media (min-width: 430px) {
  .app-body.page-company-profile .overlay .container {
    width: 557px;
  }
}
.app-body.page-company-profile .overlay .container .icon-text {
  display: flex;
  align-items: center;
  gap: 12px;
}
.app-body.page-company-profile .overlay .container .header {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
}
.app-body.page-company-profile .overlay .container .body p {
  padding: 0 4px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.app-body.page-company-profile .overlay .container .body .demo .progress {
  width: 100%;
  height: 4px;
  margin-bottom: 16px;
  background-color: #348cf9;
  border-radius: 12px;
}
.app-body.page-company-profile .overlay .container .body .demo .icon-text {
  justify-content: center;
}
.app-body.page-company-profile .overlay .container .footer {
  text-align: center;
}
.app-body.page-company-profile .overlay .container .footer button {
  width: 96px;
}
.app-body.page-search-form {
  margin: 0;
}
.app-body.page-search-form.component-general {
  background: url('../public/images/search-form-header.png') no-repeat center top;
  background-attachment: local;
}
.app-body.page-search-form .search-container {
  margin: 0 auto;
  max-width: 950px;
}
@media (max-width: 429px) {
  .app-body.page-search-form .search-container {
    margin: 0;
    padding: 0 24px;
  }
}
.app-body.page-search-form .search-container h1 {
  color: #ffffff;
  margin: 60px 0 50px 0;
}
@media (max-width: 429px) {
  .app-body.page-search-form .search-container h1 {
    margin: 48px 0;
  }
}
.app-body.page-search-form .search-container h2 {
  line-height: 24px;
}
.app-body.page-search-form .search-container .search-fields-container {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 10px 40px 0px #00000026;
}
@media (max-width: 429px) {
  .app-body.page-search-form .search-container .search-fields-container {
    padding: 32px;
  }
}
.app-body.page-search-form .search-container .onboarding-container {
  margin-top: 80px;
  margin-bottom: 40px;
}
@media (max-width: 429px) {
  .app-body.page-search-form .search-container .onboarding-container {
    margin-top: 64px;
  }
}
.app-body.page-search-form .search-container .onboarding-container .cards-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  gap: 20px;
}
@media (max-width: 429px) {
  .app-body.page-search-form .search-container .onboarding-container .cards-container {
    flex-direction: column;
    gap: 24px;
  }
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card {
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 10px 30px 0px #00000026;
}
@media (min-width: 430px) {
  .app-body.page-search-form .search-container .onboarding-container .cards-container .card {
    width: 100%;
  }
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .heading {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .heading .icon-onboarding {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .heading .icon-onboarding.step-1 {
  background: url('../public/icons/person-bounding-box.svg') #e9ecef no-repeat center center;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .heading .icon-onboarding.step-2 {
  background: url('../public/icons/person-plus.svg') #e9ecef no-repeat center center;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .heading .badge {
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .heading .badge.info {
  background-color: #76b3ff;
  color: #ffffff;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .heading .badge.success {
  color: #28a745;
  background-color: #c1edd9;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .body h6 {
  line-height: 16px;
  margin-bottom: 12px;
  font-weight: 600;
}
@media (max-width: 429px) {
  .app-body.page-search-form .search-container .onboarding-container .cards-container .card .body h6 {
    font-size: 20px;
    margin-bottom: 8px;
  }
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .body p {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
}
.app-body.page-search-form .search-container .review-container {
  margin-top: 80px;
}
@media (max-width: 429px) {
  .app-body.page-search-form .search-container .review-container {
    margin-top: 64px;
  }
}
.app-body.page-search-form .search-container .faq-container {
  margin-top: 80px;
}
@media (max-width: 429px) {
  .app-body.page-search-form .search-container .faq-container {
    margin-top: 64px;
  }
}
.app-body.page-search-form .search-container form.desktop {
  display: flex;
  align-items: stretch;
}
@media (max-width: 429px) {
  .app-body.page-search-form .search-container form.desktop {
    display: none;
  }
}
.app-body.page-search-form .search-container form.desktop .separator {
  background-color: #adb5bd;
  width: 1px;
  margin: 16px 24px 0;
}
.app-body.page-search-form .search-container form.desktop .submit-area {
  flex: 1 0 145px;
}
.app-body.page-search-form .search-container form.desktop .submit-area .form-group {
  margin-top: 66px;
}
.app-body.page-search-form .search-container form.desktop .submit-area button {
  margin-top: 20px;
}
.app-body.page-search-form .search-container form.desktop .row {
  display: flex;
  height: 54px;
  margin-bottom: 20px;
  gap: 20px;
}
.app-body.page-search-form .search-container form.desktop .row:last-of-type {
  margin-bottom: 0;
}
.app-body.page-search-form .search-container form.desktop .row .col {
  flex: 1 1 0px;
  min-width: 28%;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group {
  display: flex;
  gap: 4px;
  align-items: flex-start;
  flex-direction: column;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group label {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group label span {
  display: block;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group .input-error-group {
  width: 100%;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group .input-error-group .input-container {
  background-color: #ffffff;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group .input-error-group .input-container .search-wrapper {
  max-width: 222px;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group.contains-textarea {
  align-items: flex-start;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group.contains-textarea .input-container {
  background: none;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group.contains-textarea .input-container textarea {
  background-color: #ffffff;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 16px 1fr;
  grid-column-gap: 0px;
  grid-template-areas: 'label label label' 'min separator max';
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field label {
  grid-area: label;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field label span {
  margin-bottom: 0;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field .form-group {
  display: contents;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field .input-error-group {
  width: 101px;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field .form-group:first-of-type .input-error-group {
  grid-area: min;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field .form-group:last-of-type .input-error-group {
  grid-area: max;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field .separator {
  grid-area: separator;
  width: 8px;
  height: 2px;
  background-color: #000000;
  margin: 18px 6px;
}
@media (min-width: 430px) {
  .app-body.page-search-form .search-container form.mobile {
    display: none;
  }
}
.app-body.page-search-form .search-container form.mobile .btn-next {
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 38px;
  padding: 0 16px;
  text-align: left;
  font-weight: 400;
}
.app-body.page-search-form .backlink {
  width: 100%;
  display: block;
  height: 24px;
  background: url('../public/icons/arrow-left.svg') no-repeat left center;
  padding-left: 36px;
  padding-top: 15px;
  padding-bottom: 40px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
@media (min-width: 430px) {
  .app-body.page-search-form .backlink {
    width: fit-content;
  }
}
.app-body.page-search-form .mobile-fields .col {
  margin-bottom: 20px;
}
.app-body.page-search-form .mobile-fields .col.range-field {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 14px 1fr;
  grid-column-gap: 0px;
  grid-template-areas: 'label label label' 'min separator max';
}
.app-body.page-search-form .mobile-fields .col.range-field label {
  grid-area: label;
}
.app-body.page-search-form .mobile-fields .col.range-field label span {
  margin-bottom: 0;
}
.app-body.page-search-form .mobile-fields .col.range-field .form-group {
  display: contents;
}
.app-body.page-search-form .mobile-fields .col.range-field .input-error-group {
  width: calc(calc((calc(100vw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
}
.app-body.page-search-form .mobile-fields .col.range-field .form-group:first-of-type .input-error-group {
  grid-area: min;
}
.app-body.page-search-form .mobile-fields .col.range-field .form-group:last-of-type .input-error-group {
  grid-area: max;
}
.app-body.page-search-form .mobile-fields .col.range-field .separator {
  grid-area: separator;
  width: 8px;
  height: 2px;
  background-color: #000000;
  margin: 18px 2px;
}
.app-body.page-investor-default {
  margin: 0;
  padding: 0;
}
.app-body.page-investor-default h1 {
  color: #ffffff;
}
@media (max-width: 429px) {
  .app-body.page-investor-default h1 {
    font-size: 28px;
    line-height: 34px;
  }
}
.app-body.page-investor-default h2 {
  line-height: 24px;
}
@media (max-width: 429px) {
  .app-body.page-investor-default h2 {
    font-size: 28px;
    line-height: 24px;
  }
}
.app-body.page-investor-default .investor-cover {
  display: flex;
  align-items: center;
  height: 220px;
  background: url('../public/images/investor-header-desktop.png') no-repeat center top;
  background-attachment: local;
}
@media (min-width: 430px) {
  .app-body.page-investor-default .investor-cover {
    padding-left: calc(2 * 20px + 2 * calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 40px);
  }
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-cover {
    background: url('../public/images/investor-header-mobile.png') no-repeat center top;
    padding-left: 24px;
  }
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-cover .name-break {
    display: block;
  }
}
.app-body.page-investor-default .investor-container {
  margin: 0 auto;
  max-width: 950px;
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container {
    margin: 0;
    padding: 0 24px;
  }
}
.app-body.page-investor-default .investor-container .funds-container {
  margin-top: 80px;
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container .funds-container {
    margin-top: 64px;
  }
}
.app-body.page-investor-default .investor-container .funds-container .title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container .funds-container .title-container {
    margin-bottom: 24px;
  }
}
.app-body.page-investor-default .investor-container .funds-container .title-container .title-num {
  display: flex;
  align-items: baseline;
  gap: 12px;
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container .funds-container .title-container .title-num {
    flex-direction: column;
  }
}
.app-body.page-investor-default .investor-container .funds-container .title-container .title-num .num {
  color: #7b7c7c;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.app-body.page-investor-default .investor-container .funds-container .gray-panel {
  display: flex;
  align-items: center;
  background-color: #e9ecef;
  border-radius: 8px;
  padding: 32px;
}
@media (min-width: 430px) {
  .app-body.page-investor-default .investor-container .funds-container .gray-panel {
    justify-content: space-between;
  }
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container .funds-container .gray-panel {
    padding: 24px;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
  }
}
.app-body.page-investor-default .investor-container .funds-container .gray-panel .text {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container .funds-container .gray-panel .text {
    line-height: 26px;
  }
}
.app-body.page-investor-default .investor-container .funds-container .gray-panel .smaller-text {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  margin-top: 12px;
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container .funds-container .gray-panel .smaller-text {
    font-size: 14px;
    line-height: 20px;
  }
}
.app-body.page-investor-default .investor-container .funds-container .gray-panel .btn {
  width: 180px;
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container .funds-container .gray-panel .btn {
    width: 100%;
  }
}
.app-body.page-investor-default .investor-container .funds-container .funds-listing {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container .funds-container .funds-listing {
    flex-direction: column;
    gap: 20px;
  }
}
.app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card {
  padding: 20px;
  background-color: #f4f5f7;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (min-width: 430px) {
  .app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card {
    width: 100%;
  }
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card {
    border-radius: 4px;
  }
}
.app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card .row {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 14px;
}
.app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card .row .value {
  font-weight: 600;
}
@media (min-width: 430px) {
  .app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card .row .value {
    width: 150px;
    text-align: left;
  }
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card .row .label,
  .app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card .row .value {
    width: calc(calc((calc(100vw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
}
.app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card.empty {
    padding: 32px 0;
  }
}
.app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card.empty .central-row {
  padding-left: 36px;
  background: url('../public/icons/file-plus.svg') no-repeat left center;
  font-size: 14px;
}
.app-body.page-investor-default .investor-container .onboarding-container {
  margin-top: 80px;
  margin-bottom: 40px;
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container .onboarding-container {
    margin-top: 64px;
  }
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-top: 20px;
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container .onboarding-container .cards-container {
    flex-direction: column;
    gap: 24px;
  }
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card {
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 10px 30px 0px #00000026;
}
@media (min-width: 430px) {
  .app-body.page-investor-default .investor-container .onboarding-container .cards-container .card {
    width: 100%;
  }
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .icon-onboarding {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .icon-onboarding.step-1 {
  background: url('../public/icons/person-bounding-box.svg') #e9ecef no-repeat center center;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .icon-onboarding.step-2 {
  background: url('../public/icons/cash-stack.svg') #e9ecef no-repeat center center;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .badge {
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .badge.info {
  background-color: #76b3ff;
  color: #ffffff;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .badge.success {
  color: #28a745;
  background-color: #c1edd9;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .body h6 {
  line-height: 16px;
  margin-bottom: 12px;
  font-weight: 600;
}
@media (max-width: 429px) {
  .app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .body h6 {
    font-size: 20px;
    margin-bottom: 8px;
  }
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .body p {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
}
.app-body.page-investor-funds-listing.component-portfolio-companies h4,
.app-body.page-investor-funds-listing.component-default h4 {
  font-weight: 500;
}
.app-body.page-investor-funds-listing.component-portfolio-companies h6,
.app-body.page-investor-funds-listing.component-default h6 {
  font-weight: 400;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .gray-title-panel,
.app-body.page-investor-funds-listing.component-default .gray-title-panel {
  border-radius: 16px;
  padding: 36px 32px;
  background-color: #f4f5f7;
}
@media (min-width: 430px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .gray-title-panel,
  .app-body.page-investor-funds-listing.component-default .gray-title-panel {
    padding-left: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    margin-bottom: 32px;
  }
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .gray-title-panel,
  .app-body.page-investor-funds-listing.component-default .gray-title-panel {
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 24px;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .header,
.app-body.page-investor-funds-listing.component-default .table-responsive .header {
  padding-left: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  padding-bottom: 16px;
  display: flex;
  gap: 20px;
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .header,
  .app-body.page-investor-funds-listing.component-default .table-responsive .header {
    display: none;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .header .item,
.app-body.page-investor-funds-listing.component-default .table-responsive .header .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row,
.app-body.page-investor-funds-listing.component-default .table-responsive .row {
  min-height: 72px;
  border-radius: 4px;
  background-color: #f4f5f7;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row {
    padding: 16px;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item,
.app-body.page-investor-funds-listing.component-default .table-responsive .row .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item.strong .value,
.app-body.page-investor-funds-listing.component-default .table-responsive .row .item.strong .value {
  font-weight: 600;
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .item {
    width: 100%;
    display: flex;
    gap: 12px;
  }
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item .label,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .item .label,
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item .value,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .item .value {
    width: calc(calc((calc(100vw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item .value,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .item .value {
    font-weight: 600;
  }
}
@media (min-width: 430px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item .label,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .item .label {
    display: none;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .actions,
.app-body.page-investor-funds-listing.component-default .table-responsive .row .actions {
  display: flex;
  gap: 20px;
  align-items: center;
}
@media (min-width: 430px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .actions,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .actions {
    flex-grow: 1;
    justify-content: flex-end;
    padding-right: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  }
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .actions,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .actions .btn,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .actions .btn {
    display: block;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .icon-trash,
.app-body.page-investor-funds-listing.component-default .table-responsive .row .icon-trash {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url('../public/icons/trash.svg') no-repeat center center;
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .icon-trash,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .icon-trash {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .create-fund-panel,
.app-body.page-investor-funds-listing.component-default .create-fund-panel {
  margin-top: 16px;
  border-radius: 4px;
  background-color: #f4f5f7;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .create-fund-panel .icon-add,
.app-body.page-investor-funds-listing.component-default .create-fund-panel .icon-add {
  width: 24px;
  height: 24px;
  background: url('../public/icons/file-plus.svg') no-repeat center center;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .create-fund-panel .text,
.app-body.page-investor-funds-listing.component-default .create-fund-panel .text {
  font-size: 14px;
  line-height: 14px;
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .create-fund-panel,
  .app-body.page-investor-funds-listing.component-default .create-fund-panel {
    margin-top: 24px;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .tabs,
.app-body.page-investor-funds-listing.component-default .tabs {
  border-bottom: 1px solid #348cf9;
  display: flex;
  align-items: flex-start;
  gap: 22px;
  margin-bottom: 16px;
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .tabs,
  .app-body.page-investor-funds-listing.component-default .tabs {
    margin-bottom: 24px;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .tabs .tab,
.app-body.page-investor-funds-listing.component-default .tabs .tab {
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .tabs .tab,
  .app-body.page-investor-funds-listing.component-default .tabs .tab {
    width: 100%;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .tabs .tab.active,
.app-body.page-investor-funds-listing.component-default .tabs .tab.active {
  background-color: #348cf9;
  color: #ffffff;
  font-weight: 500;
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .tabs .tab .desktop-text,
  .app-body.page-investor-funds-listing.component-default .tabs .tab .desktop-text {
    display: none;
  }
}
@media (min-width: 430px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .tabs .tab .mobile-text,
  .app-body.page-investor-funds-listing.component-default .tabs .tab .mobile-text {
    display: none;
  }
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-create-portfolio-company,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company,
  .app-body.page-investor-funds-listing.component-create-fund,
  .app-body.page-investor-funds-listing.component-edit-fund {
    height: calc(100vh - 150px);
    flex-grow: 0;
  }
}
.app-body.page-investor-funds-listing.component-create-portfolio-company h4,
.app-body.page-investor-funds-listing.component-edit-portfolio-company h4,
.app-body.page-investor-funds-listing.component-create-fund h4,
.app-body.page-investor-funds-listing.component-edit-fund h4 {
  font-weight: 500;
}
.app-body.page-investor-funds-listing.component-create-portfolio-company h6,
.app-body.page-investor-funds-listing.component-edit-portfolio-company h6,
.app-body.page-investor-funds-listing.component-create-fund h6,
.app-body.page-investor-funds-listing.component-edit-fund h6 {
  font-weight: 400;
}
@media (min-width: 430px) {
  .app-body.page-investor-funds-listing.component-create-portfolio-company h6,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company h6,
  .app-body.page-investor-funds-listing.component-create-fund h6,
  .app-body.page-investor-funds-listing.component-edit-fund h6 {
    display: none;
  }
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-create-portfolio-company .header-backlink,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company .header-backlink,
  .app-body.page-investor-funds-listing.component-create-fund .header-backlink,
  .app-body.page-investor-funds-listing.component-edit-fund .header-backlink {
    display: flex;
    flex-direction: column-reverse;
  }
}
.app-body.page-investor-funds-listing.component-create-portfolio-company .header-backlink .gray-title-panel,
.app-body.page-investor-funds-listing.component-edit-portfolio-company .header-backlink .gray-title-panel,
.app-body.page-investor-funds-listing.component-create-fund .header-backlink .gray-title-panel,
.app-body.page-investor-funds-listing.component-edit-fund .header-backlink .gray-title-panel {
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  padding: 36px 32px;
  background-color: #f4f5f7;
}
@media (min-width: 430px) {
  .app-body.page-investor-funds-listing.component-create-portfolio-company .header-backlink .gray-title-panel,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company .header-backlink .gray-title-panel,
  .app-body.page-investor-funds-listing.component-create-fund .header-backlink .gray-title-panel,
  .app-body.page-investor-funds-listing.component-edit-fund .header-backlink .gray-title-panel {
    padding-left: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    padding-right: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    margin-bottom: 16px;
  }
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-create-portfolio-company .header-backlink .gray-title-panel,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company .header-backlink .gray-title-panel,
  .app-body.page-investor-funds-listing.component-create-fund .header-backlink .gray-title-panel,
  .app-body.page-investor-funds-listing.component-edit-fund .header-backlink .gray-title-panel {
    border-radius: 4px;
    padding: 16px;
    flex-direction: column;
    gap: 12px;
  }
}
.app-body.page-investor-funds-listing.component-create-portfolio-company .header-backlink .gray-title-panel .title-section,
.app-body.page-investor-funds-listing.component-edit-portfolio-company .header-backlink .gray-title-panel .title-section,
.app-body.page-investor-funds-listing.component-create-fund .header-backlink .gray-title-panel .title-section,
.app-body.page-investor-funds-listing.component-edit-fund .header-backlink .gray-title-panel .title-section {
  display: flex;
  align-items: center;
  gap: 16px;
}
@media (min-width: 430px) {
  .app-body.page-investor-funds-listing.component-create-portfolio-company .header-backlink .gray-title-panel .actions,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company .header-backlink .gray-title-panel .actions,
  .app-body.page-investor-funds-listing.component-create-fund .header-backlink .gray-title-panel .actions,
  .app-body.page-investor-funds-listing.component-edit-fund .header-backlink .gray-title-panel .actions {
    display: flex;
    gap: 20px;
  }
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-create-portfolio-company .header-backlink .gray-title-panel .actions,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company .header-backlink .gray-title-panel .actions,
  .app-body.page-investor-funds-listing.component-create-fund .header-backlink .gray-title-panel .actions,
  .app-body.page-investor-funds-listing.component-edit-fund .header-backlink .gray-title-panel .actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 24px 24px;
    background: #ffffff;
    display: flex;
    gap: 12px;
    box-shadow: 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
  }
  .app-body.page-investor-funds-listing.component-create-portfolio-company .header-backlink .gray-title-panel .actions .btn,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company .header-backlink .gray-title-panel .actions .btn,
  .app-body.page-investor-funds-listing.component-create-fund .header-backlink .gray-title-panel .actions .btn,
  .app-body.page-investor-funds-listing.component-edit-fund .header-backlink .gray-title-panel .actions .btn {
    width: calc(calc((calc(100vw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
}
.app-body.page-investor-funds-listing.component-create-portfolio-company .backlink,
.app-body.page-investor-funds-listing.component-edit-portfolio-company .backlink,
.app-body.page-investor-funds-listing.component-create-fund .backlink,
.app-body.page-investor-funds-listing.component-edit-fund .backlink {
  display: block;
  padding-left: 28px;
  background: url('../public/icons/arrow-left.svg') no-repeat left center;
  margin: 32px 0 24px 0;
}
@media (min-width: 430px) {
  .app-body.page-investor-funds-listing.component-create-portfolio-company .backlink,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company .backlink,
  .app-body.page-investor-funds-listing.component-create-fund .backlink,
  .app-body.page-investor-funds-listing.component-edit-fund .backlink {
    width: fit-content;
  }
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-create-portfolio-company .backlink,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company .backlink,
  .app-body.page-investor-funds-listing.component-create-fund .backlink,
  .app-body.page-investor-funds-listing.component-edit-fund .backlink {
    margin: 27px 0;
  }
}
.app-body.page-investor-funds-listing.component-create-portfolio-company .tabs,
.app-body.page-investor-funds-listing.component-edit-portfolio-company .tabs,
.app-body.page-investor-funds-listing.component-create-fund .tabs,
.app-body.page-investor-funds-listing.component-edit-fund .tabs {
  border-bottom: 1px solid #348cf9;
  display: flex;
  align-items: flex-start;
  gap: 22px;
  margin-bottom: 16px;
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-create-portfolio-company .tabs,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company .tabs,
  .app-body.page-investor-funds-listing.component-create-fund .tabs,
  .app-body.page-investor-funds-listing.component-edit-fund .tabs {
    margin: 24px 0;
    overflow: auto;
  }
}
.app-body.page-investor-funds-listing.component-create-portfolio-company .tabs .tab,
.app-body.page-investor-funds-listing.component-edit-portfolio-company .tabs .tab,
.app-body.page-investor-funds-listing.component-create-fund .tabs .tab,
.app-body.page-investor-funds-listing.component-edit-fund .tabs .tab {
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-create-portfolio-company .tabs .tab,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company .tabs .tab,
  .app-body.page-investor-funds-listing.component-create-fund .tabs .tab,
  .app-body.page-investor-funds-listing.component-edit-fund .tabs .tab {
    width: 100%;
    white-space: nowrap;
  }
}
.app-body.page-investor-funds-listing.component-create-portfolio-company .tabs .tab.active,
.app-body.page-investor-funds-listing.component-edit-portfolio-company .tabs .tab.active,
.app-body.page-investor-funds-listing.component-create-fund .tabs .tab.active,
.app-body.page-investor-funds-listing.component-edit-fund .tabs .tab.active {
  background-color: #348cf9;
  color: #ffffff;
  font-weight: 500;
}
.app-body.page-investor-funds-listing.component-create-portfolio-company .tabs .tab.error-tab,
.app-body.page-investor-funds-listing.component-edit-portfolio-company .tabs .tab.error-tab,
.app-body.page-investor-funds-listing.component-create-fund .tabs .tab.error-tab,
.app-body.page-investor-funds-listing.component-edit-fund .tabs .tab.error-tab {
  color: #dc3545;
  padding-left: 24px;
  background: url('../public/icons/exclamation-circle.svg') no-repeat left center;
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-create-portfolio-company .tabs .tab .desktop-text,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company .tabs .tab .desktop-text,
  .app-body.page-investor-funds-listing.component-create-fund .tabs .tab .desktop-text,
  .app-body.page-investor-funds-listing.component-edit-fund .tabs .tab .desktop-text {
    display: none;
  }
}
@media (min-width: 430px) {
  .app-body.page-investor-funds-listing.component-create-portfolio-company .tabs .tab .mobile-text,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company .tabs .tab .mobile-text,
  .app-body.page-investor-funds-listing.component-create-fund .tabs .tab .mobile-text,
  .app-body.page-investor-funds-listing.component-edit-fund .tabs .tab .mobile-text {
    display: none;
  }
}
.app-body.page-investor-funds-listing.component-create-portfolio-company .form-body,
.app-body.page-investor-funds-listing.component-edit-portfolio-company .form-body,
.app-body.page-investor-funds-listing.component-create-fund .form-body,
.app-body.page-investor-funds-listing.component-edit-fund .form-body {
  background-color: #f4f5f7;
  border-radius: 16px;
}
@media (max-width: 429px) {
  .app-body.page-investor-funds-listing.component-create-portfolio-company .form-body,
  .app-body.page-investor-funds-listing.component-edit-portfolio-company .form-body,
  .app-body.page-investor-funds-listing.component-create-fund .form-body,
  .app-body.page-investor-funds-listing.component-edit-fund .form-body {
    background-color: #ffffff;
    border-radius: 0;
    padding: 0;
  }
}
@media (max-width: 429px) {
  .app-body.page-user-profile {
    overflow: auto;
    height: calc(100vh - 140px);
    flex-grow: 0;
  }
}
.app-body.page-user-profile h4 {
  font-weight: 500;
}
.app-body.page-user-profile h6 {
  font-weight: 400;
}
.app-body.page-user-profile .gray-title-panel {
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  padding: 36px 32px;
  background-color: #f4f5f7;
}
@media (min-width: 430px) {
  .app-body.page-user-profile .gray-title-panel {
    padding-left: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    padding-right: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    margin-bottom: 32px;
  }
}
@media (max-width: 429px) {
  .app-body.page-user-profile .gray-title-panel {
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 24px;
  }
}
@media (min-width: 430px) {
  .app-body.page-user-profile .gray-title-panel .actions {
    display: flex;
    gap: 20px;
  }
}
@media (max-width: 429px) {
  .app-body.page-user-profile .gray-title-panel .actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 24px 24px;
    background: #ffffff;
    display: flex;
    gap: 12px;
    box-shadow: 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
  }
  .app-body.page-user-profile .gray-title-panel .actions .btn {
    width: calc(calc((calc(100vw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
}
.app-body.page-user-profile .tabs {
  border-bottom: 1px solid #348cf9;
  display: flex;
  align-items: flex-start;
  gap: 22px;
  margin-bottom: 16px;
}
@media (max-width: 429px) {
  .app-body.page-user-profile .tabs {
    margin: 24px 0;
    overflow: auto;
  }
}
.app-body.page-user-profile .tabs .tab {
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  display: flex;
  gap: 8px;
  align-items: center;
}
@media (max-width: 429px) {
  .app-body.page-user-profile .tabs .tab {
    width: 100%;
    white-space: nowrap;
    justify-content: center;
  }
}
.app-body.page-user-profile .tabs .tab.active {
  background-color: #348cf9;
  color: #ffffff;
  font-weight: 500;
}
@media (max-width: 429px) {
  .app-body.page-user-profile .tabs .tab.only-desktop {
    display: none;
  }
}
@media (min-width: 430px) {
  .app-body.page-user-profile .tabs .tab.only-mobile {
    display: none;
  }
}
@media (max-width: 429px) {
  .app-body.page-user-profile .tabs .tab .desktop-text {
    display: none;
  }
}
@media (min-width: 430px) {
  .app-body.page-user-profile .tabs .tab .mobile-text {
    display: none;
  }
}
.app-body.page-user-profile .tabs .tab .icon {
  width: 16px;
  height: 16px;
}
.app-body.page-user-profile .tabs .tab .icon.icon-user {
  background: url('../public/icons/grid.svg') no-repeat center center;
}
.app-body.page-user-profile .tabs .tab .icon.icon-user.active {
  filter: invert(1);
}
.app-body.page-user-profile .tabs .tab .icon.icon-change-password {
  background: url('../public/icons/key.svg') no-repeat center center;
}
.app-body.page-user-profile .tabs .tab .icon.icon-change-password.active {
  filter: invert(1);
}
.app-body.page-user-profile .tabs .tab .icon.icon-deactivate-account {
  background: url('../public/icons/person-slash.svg') no-repeat center center;
}
.app-body.page-user-profile .tabs .tab .icon.icon-deactivate-account.active {
  filter: invert(1);
}
.app-body.page-user-profile .tabs .tab .icon.icon-settings {
  background: url('../public/icons/sliders-black.svg') no-repeat center center;
}
.app-body.page-user-profile .tabs .tab .icon.icon-settings.active {
  filter: invert(1);
}
.app-body.page-user-profile .form-body {
  background-color: #f4f5f7;
  border-radius: 16px;
}
@media (max-width: 429px) {
  .app-body.page-user-profile .form-body {
    background-color: #ffffff;
    border-radius: 0;
    padding: 0;
  }
}
.app-body.page-user-profile .form-body label {
  padding-left: 0;
}
.app-body.page-user-profile .mobile-settings-container {
  width: 100%;
}
.app-body.page-user-profile .mobile-settings-container .item {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  background: url('../public/icons/arrow-right.svg') no-repeat right center;
}
.app-body.page-user-profile .mobile-settings-container .item a {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 48px;
  font-weight: 600;
}
@media (min-width: 430px) {
  .app-body.page-user-profile .confirm-container {
    padding: 32px 117px;
  }
  .app-body.page-user-profile .confirm-container .title {
    height: 24px;
    padding-left: 42px;
    background: url('../public/icons/info-circle.svg') no-repeat left center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
  }
  .app-body.page-user-profile .confirm-container .body {
    margin: 20px 0 0 34px;
    width: 550px;
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 429px) {
  .app-body.page-user-profile .confirm-container .backlink {
    width: 100%;
    display: block;
    height: 24px;
    background: url('../public/icons/arrow-left.svg') no-repeat left center;
  }
  .app-body.page-user-profile .confirm-container .title {
    margin-top: 20px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
  }
  .app-body.page-user-profile .confirm-container .body {
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
  }
}
@media (min-width: 430px) {
  .app-body.page-user-profile .mobile-tab-header {
    display: none;
  }
}
.app-body.page-user-profile .mobile-tab-header .backlink {
  width: 100%;
  display: block;
  height: 24px;
  margin-bottom: 20px;
  background: url('../public/icons/arrow-left.svg') no-repeat left center;
}
.app-body.page-user-profile .mobile-tab-header .title {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.app-body.page-user-profile .mobile-tab-header p {
  line-height: 20px;
  font-size: 14px;
}
.app-body.page-search-listing .mobile-filters-container {
  height: calc(100vh - 140px);
  overflow: auto;
}
.app-body.page-search-listing .mobile-filters-container .backlink {
  font-size: 16px;
  font-weight: 600;
  padding-left: 36px;
  background: url('../public/icons/arrow-left.svg') no-repeat left center;
  margin: 12px 0 32px 0;
  width: 100%;
}
.app-body.page-search-listing .mobile-filters-container .col {
  margin-bottom: 20px;
}
.app-body.page-search-listing .mobile-filters-container .col.range-field {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 14px 1fr;
  grid-column-gap: 0px;
  grid-template-areas: 'label label label' 'min separator max';
}
.app-body.page-search-listing .mobile-filters-container .col.range-field label {
  grid-area: label;
}
.app-body.page-search-listing .mobile-filters-container .col.range-field label span {
  margin-bottom: 0;
}
.app-body.page-search-listing .mobile-filters-container .col.range-field .form-group {
  display: contents;
}
.app-body.page-search-listing .mobile-filters-container .col.range-field .input-error-group {
  width: calc(calc((calc(100vw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
}
.app-body.page-search-listing .mobile-filters-container .col.range-field .form-group:first-of-type .input-error-group {
  grid-area: min;
}
.app-body.page-search-listing .mobile-filters-container .col.range-field .form-group:last-of-type .input-error-group {
  grid-area: max;
}
.app-body.page-search-listing .mobile-filters-container .col.range-field .separator {
  grid-area: separator;
  width: 8px;
  height: 2px;
  background-color: #000000;
  margin: 18px 2px;
}
.app-body.page-search-listing .form-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 24px;
  background: #ffffff;
  display: flex;
  gap: 12px;
  box-shadow: 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
}
@media (min-width: 430px) {
  .app-body.page-search-listing .form-footer {
    display: none;
  }
}
@media (min-width: 430px) {
  .app-body.page-search-listing .listing-layout {
    margin-top: 43px;
    display: flex;
    gap: 20px;
  }
}
.app-body.page-search-listing .listing-layout .desktop-filters {
  align-self: flex-start;
  border-radius: 8px;
  background-color: #f4f5f7;
  flex: 0 0 calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
  padding: 20px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .listing-layout .desktop-filters {
    display: none;
  }
}
.app-body.page-search-listing .listing-layout .desktop-filters .filter-category {
  border-top: 0.5px solid #7b7c7c;
}
.app-body.page-search-listing .listing-layout .desktop-filters .filter-category button {
  padding: 22px 14px;
  font-size: 14px;
  line-height: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.app-body.page-search-listing .listing-layout .desktop-filters .filter-category button .expanded {
  transform: rotate(180deg);
}
.app-body.page-search-listing .listing-layout .desktop-filters .filter-category label {
  font-size: 12px;
}
.app-body.page-search-listing .listing-layout .desktop-filters .input-container {
  background: #ffffff;
  border-radius: 6px;
}
.app-body.page-search-listing .listing-layout .desktop-filters .input-container.icon-select-downarrow {
  background: url('../public/icons/chevron-down.svg') #ffffff no-repeat calc(100% - 16px) center;
}
.app-body.page-search-listing .listing-layout .desktop-filters .input-container .multiselect-container {
  max-width: calc(calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px) - 40px);
}
.app-body.page-search-listing .listing-layout .desktop-filters .col {
  margin-bottom: 20px;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col label {
  padding-left: 16px;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field {
  display: grid;
  margin-bottom: 4px;
  grid-template-columns: 1fr 24px 1fr;
  grid-template-rows: 24px 1fr 16px;
  grid-column-gap: 0px;
  grid-template-areas: 'label label label' 'min separator max' 'err err err';
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field label {
  grid-area: label;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field label span {
  margin-bottom: 0;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field .form-group {
  display: contents;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field .input-error-group {
  width: 100%;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field .form-group:first-of-type .input-error-group {
  grid-area: min;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field .form-group:last-of-type .input-error-group {
  grid-area: max;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field .range-error-msg-input {
  grid-area: err;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field .separator {
  grid-area: separator;
  width: 8px;
  height: 2px;
  background-color: #000000;
  margin: 18px 7px;
}
.app-body.page-search-listing .listing-layout .results {
  flex-grow: 1;
}
@media (min-width: 430px) {
  .app-body.page-search-listing .listing-layout .results .mobile-filters {
    display: none;
  }
}
.app-body.page-search-listing .listing-layout .results .results-header {
  display: flex;
  width: 100%;
  padding-bottom: 16px;
  padding-left: 32px;
  border-bottom: 1px solid #348cf9;
  justify-content: space-between;
  gap: 16px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .listing-layout .results .results-header {
    display: none;
  }
}
.app-body.page-search-listing .listing-layout .results .results-header .cols {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 14px;
  line-height: 14px;
  gap: 8px;
}
.app-body.page-search-listing .listing-layout .results .results-header .cols .col {
  width: 20%;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions {
  min-width: 282px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .total-num {
  text-align: right;
  margin-bottom: 8px;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .buttons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .buttons .icon-save-search {
  background: url('../public/icons/folder2.svg') no-repeat right center;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .buttons .icon-download-search {
  background: url('../public/icons/box-arrow-in-down.svg') no-repeat right center;
}
.app-body.page-search-listing .listing-layout .results .item {
  border-radius: 8px;
  margin-top: 16px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  gap: 16px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .listing-layout .results .item {
    flex-direction: column;
    padding: 24px;
  }
}
.app-body.page-search-listing .listing-layout .results .item .cols {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .listing-layout .results .item .cols {
    flex-direction: column;
  }
  .app-body.page-search-listing .listing-layout .results .item .cols .col {
    align-items: center;
  }
}
@media (min-width: 430px) {
  .app-body.page-search-listing .listing-layout .results .item .cols .col {
    width: 20%;
  }
}
.app-body.page-search-listing .listing-layout .results .item .actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 250px;
  gap: 16px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .listing-layout .results .item .actions {
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }
  .app-body.page-search-listing .listing-layout .results .item .actions .col {
    padding: 0;
  }
}
.app-body.page-search-listing .listing-layout .results .item:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .listing-layout .results .item .col.no-mobile {
    display: none;
  }
}
@media (min-width: 430px) {
  .app-body.page-search-listing .listing-layout .results .item .col.no-desktop {
    display: none;
  }
}
.app-body.page-search-listing .listing-layout .results .item .col.mobile-badge {
  padding: 0;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .listing-layout .results .item .col {
    width: 100%;
    border-bottom: 0.5px solid #adb5bd;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
  }
}
@media (max-width: 429px) {
  .app-body.page-search-listing .listing-layout .results .item .col.no-border {
    border-bottom: 0;
  }
}
.app-body.page-search-listing .listing-layout .results .item .col .btn {
  padding: 10px;
}
@media (min-width: 430px) {
  .app-body.page-search-listing .listing-layout .results .item .label {
    display: none;
  }
}
@media (max-width: 429px) {
  .app-body.page-search-listing .listing-layout .results .item .label {
    font-weight: 500;
    color: #000000;
  }
}
.app-body.page-search-listing .listing-layout .results .item .value {
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  line-height: 12px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .listing-layout .results .item .value {
    width: 50%;
  }
}
.app-body.page-search-listing .listing-layout .results .no-results {
  background-color: #f4f5f7;
}
@media (min-width: 430px) {
  .app-body.page-search-listing .listing-layout .results .no-results {
    padding: 104px calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    margin-top: 16px;
  }
}
@media (max-width: 429px) {
  .app-body.page-search-listing .listing-layout .results .no-results {
    padding: 24px;
  }
}
.app-body.page-search-listing .listing-layout .results .no-results h5 {
  line-height: 20px;
  margin-bottom: 16px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .listing-layout .results .no-results h5 {
    line-height: 26px;
    margin-bottom: 24px;
  }
}
.app-body.page-search-listing .listing-layout .results .no-results .text-help {
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 12px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .listing-layout .results .no-results .text-help {
    margin-bottom: 24px;
  }
}
.app-body.page-search-listing .listing-layout .results .no-results ul {
  list-style: disc;
  padding-left: 16px;
  font-size: 14px;
  line-height: 20px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .listing-layout .results .no-results ul li {
    margin-bottom: 16px;
  }
  .app-body.page-search-listing .listing-layout .results .no-results ul li:last-of-type {
    margin-bottom: 0;
  }
}
.app-body.page-search-listing .listing-layout .results .no-results a {
  color: #348cf9;
  text-decoration: underline;
  font-weight: 600;
}
.app-body.page-search-listing .listing-layout .results .badge {
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
}
.app-body.page-search-listing .listing-layout .results .badge.success {
  color: #28a745;
  background-color: #c1edd9;
}
.app-body.page-search-listing .listing-layout .results .badge.warning {
  color: #ed7200;
  background-color: #ffe79e;
}
.app-body.page-search-listing .listing-layout .results .badge.info {
  color: #7b7c7c;
  background-color: #e9ecef;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters {
  height: 51px;
  margin-top: 12px;
  margin-bottom: 32px;
}
@media (min-width: 430px) {
  .app-body.page-search-listing .listing-layout .results .mobile-filters {
    display: none;
  }
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .total-number {
  text-align: right;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 12px;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container {
  display: flex;
  justify-content: space-between;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .filters-btn {
  background: url('../public/icons/sliders-black.svg') no-repeat left center;
  padding-left: 28px;
  width: 76px;
  font-weight: 500;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions {
  display: flex;
  gap: 27px;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .btn {
  width: 22px;
  height: 22px;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .btn.icon-save-search {
  background: url('../public/icons/save-floppy.svg') no-repeat center center;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .btn.icon-download-search {
  background: url('../public/icons/download.svg') no-repeat center center;
}
.app-body.page-search-listing .listing-layout .paging {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 32px;
}
.app-body.page-search-listing .listing-layout .paging .btn {
  width: 62px;
}
.app-body.page-search-listing .overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
.app-body.page-search-listing .overlay .container {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container {
    margin: 0 24px;
    padding: 24px 0;
    width: 100%;
    min-height: 430px;
  }
}
@media (min-width: 430px) {
  .app-body.page-search-listing .overlay .container {
    width: 712px;
    min-height: 400px;
  }
}
.app-body.page-search-listing .overlay .container .close {
  background: url('../public/icons/x.svg') no-repeat right center;
  padding-right: 20px;
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  cursor: pointer;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container .close {
    text-align: right;
    font-size: 16px;
    height: 20px;
    line-height: 20px;
  }
}
.app-body.page-search-listing .overlay .container .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container .header {
    flex-direction: column-reverse;
    gap: 12px;
    padding: 0 24px;
  }
}
.app-body.page-search-listing .overlay .container .errors {
  height: 20px;
  line-height: 20px;
  margin-top: 8px;
  padding-left: 28px;
  color: #dc3545;
  font-size: 12px;
  background: url('../public/icons/exclamation-circle.svg') no-repeat left center;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container .errors {
    margin-top: 16px;
  }
}
.app-body.page-search-listing .overlay .container.popup-details .header {
  border-bottom: 0.5px solid #348cf9;
}
.app-body.page-search-listing .overlay .container.popup-message .header .tabs,
.app-body.page-search-listing .overlay .container.popup-details .header .tabs {
  display: flex;
  justify-content: flex-start;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-message .header .tabs,
  .app-body.page-search-listing .overlay .container.popup-details .header .tabs {
    justify-content: space-around;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .header .tabs .tab,
.app-body.page-search-listing .overlay .container.popup-details .header .tabs .tab {
  padding: 10px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #000000;
  border-radius: 4px;
  cursor: pointer;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-message .header .tabs .tab,
  .app-body.page-search-listing .overlay .container.popup-details .header .tabs .tab {
    font-size: 14px;
    line-height: 14px;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .header .tabs .tab.active,
.app-body.page-search-listing .overlay .container.popup-details .header .tabs .tab.active {
  border-bottom: 2px solid #348cf9;
  color: #ffffff;
  background-color: #348cf9;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-message .content,
  .app-body.page-search-listing .overlay .container.popup-details .content {
    padding: 0 24px;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .content .row,
.app-body.page-search-listing .overlay .container.popup-details .content .row {
  padding: 8px 0;
  height: 36px;
  border-bottom: 0.5px solid #adb5bd;
  display: flex;
  align-items: center;
}
.app-body.page-search-listing .overlay .container.popup-message .content .row:last-of-type,
.app-body.page-search-listing .overlay .container.popup-details .content .row:last-of-type {
  border-bottom: 0;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .row,
  .app-body.page-search-listing .overlay .container.popup-details .content .row {
    padding: 16px 0;
    height: fit-content;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .content .row .bullet,
.app-body.page-search-listing .overlay .container.popup-details .content .row .bullet {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #f4f5f7;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .row .bullet,
  .app-body.page-search-listing .overlay .container.popup-details .content .row .bullet {
    display: none;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .content .row .value,
.app-body.page-search-listing .overlay .container.popup-details .content .row .value,
.app-body.page-search-listing .overlay .container.popup-message .content .row .label,
.app-body.page-search-listing .overlay .container.popup-details .content .row .label {
  font-size: 12px;
  line-height: 12px;
}
.app-body.page-search-listing .overlay .container.popup-message .content .row .label,
.app-body.page-search-listing .overlay .container.popup-details .content .row .label {
  font-weight: 600;
  padding-left: 12px;
  width: 230px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .row .label,
  .app-body.page-search-listing .overlay .container.popup-details .content .row .label {
    flex: 0 0 50%;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .content .field,
.app-body.page-search-listing .overlay .container.popup-details .content .field {
  margin-bottom: 20px;
}
.app-body.page-search-listing .overlay .container.popup-message .content .field.readonly,
.app-body.page-search-listing .overlay .container.popup-details .content .field.readonly {
  display: flex;
}
.app-body.page-search-listing .overlay .container.popup-message .content .field.readonly label,
.app-body.page-search-listing .overlay .container.popup-details .content .field.readonly label {
  width: 120px;
}
.app-body.page-search-listing .overlay .container.popup-message .content .field label,
.app-body.page-search-listing .overlay .container.popup-details .content .field label {
  padding-left: 16px;
}
.app-body.page-search-listing .overlay .container.popup-message .content .actions,
.app-body.page-search-listing .overlay .container.popup-details .content .actions {
  display: flex;
  justify-content: flex-end;
}
.app-body.page-search-listing .overlay .container.popup-message .header .text {
  display: flex;
  gap: 30px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-message .header .text {
    justify-content: space-between;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .header .text label {
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
}
.app-body.page-search-listing .overlay .container.popup-message .header .text .value {
  font-size: 14px;
  line-height: 12px;
}
.app-body.page-search-listing .overlay .container.popup-message .separator {
  border-top: 0.5px solid #adb5bd;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-message .separator {
    margin-left: 24px;
    margin-right: 24px;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .content .info {
  font-size: 12px;
  line-height: 12px;
  font-style: italic;
  margin-bottom: 24px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .info {
    line-height: 16px;
    margin-bottom: 100px;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .content .success {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  text-align: center;
  height: 380px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .success {
    flex-direction: column;
    padding: 0 38px;
  }
}
@media (min-width: 430px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .success {
    width: 332px;
    margin: 0 auto;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .content .success img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .success .names {
    display: block;
  }
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-message .actions .btn {
    width: 100%;
  }
}
.app-body.page-search-listing .overlay .container.popup-save-search {
  min-height: 160px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-save-search {
    min-height: 210px;
  }
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-save-search .header {
    gap: 36px;
  }
}
.app-body.page-search-listing .overlay .container.popup-save-search .success-text {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 306px;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-save-search .success-text {
    flex-direction: column;
    gap: 4px;
  }
}
.app-body.page-search-listing .overlay .container.popup-save-search .success-text h4 {
  font-size: 20px;
  line-height: 26px;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-save-search .success-text h4 {
    max-width: 250px;
  }
}
.app-body.page-search-listing .overlay .container.popup-save-search .success-text img {
  width: 24px;
  height: 24px;
}
.app-body.page-search-listing .overlay .container.popup-save-search .success-text .loader-wrapper {
  height: 50px;
}
.app-body.page-search-listing .overlay .container.popup-save-search .success-actions {
  display: flex;
  justify-content: center;
}
.app-body.page-search-listing .overlay .container.popup-save-search .success-actions a {
  display: block;
  width: auto;
}
.app-body.page-search-listing .overlay .container.popup-save-search h5 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
}
.app-body.page-search-listing .overlay .container.popup-save-search p {
  margin: 0;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-save-search p {
    padding: 0 24px;
  }
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-save-search .form-group {
    padding: 0 24px;
  }
}
.app-body.page-search-listing .overlay .container.popup-save-search .actions {
  text-align: center;
}
@media (max-width: 429px) {
  .app-body.page-search-listing .overlay .container.popup-save-search .errors {
    padding: 0 24px;
  }
}
.transparent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
.transparent-overlay .container {
  padding: 60px 32px;
  background-color: #ffffff;
  border-radius: 8px;
}
@media (max-width: 429px) {
  .transparent-overlay .container {
    margin: 0 24px;
    padding: 24px 24px;
    width: 100%;
    position: fixed;
    top: 33%;
  }
}
@media (min-width: 430px) {
  .transparent-overlay .container {
    width: 589px;
    box-shadow: 0px 10px 40px 0px #00000026;
  }
}
.transparent-overlay .container .title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 32px;
}
@media (max-width: 429px) {
  .transparent-overlay .container .title {
    line-height: 30px;
    margin-bottom: 40px;
  }
}
.transparent-overlay .container .actions {
  display: flex;
  gap: 32px;
}
@media (max-width: 429px) {
  .transparent-overlay .container .actions {
    flex-direction: column;
    gap: 24px;
  }
}
.app-body.page-saved-search-listing .results-header {
  display: flex;
  width: 100%;
  padding: 0 0 0 calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  justify-content: space-between;
  gap: 16px;
}
.app-body.page-saved-search-listing .results-header .cols {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 14px;
  line-height: 14px;
  gap: 8px;
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .results-header .cols {
    display: none;
  }
}
.app-body.page-saved-search-listing .results-header .cols .col {
  width: 30%;
}
.app-body.page-saved-search-listing .results-header .actions {
  min-width: 223px;
  text-align: right;
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .results-header .actions {
    width: 100%;
    padding-top: 12px;
  }
}
.app-body.page-saved-search-listing .item {
  margin-top: 16px;
  border-radius: 4px;
  background-color: #e9ecef;
  padding: 16px 0 16px calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .item {
    flex-direction: column;
    padding: 20px;
  }
}
.app-body.page-saved-search-listing .item .cols {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .item .cols {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .app-body.page-saved-search-listing .item .cols .col {
    align-items: center;
  }
}
@media (min-width: 430px) {
  .app-body.page-saved-search-listing .item .cols .col {
    width: 30%;
  }
  .app-body.page-saved-search-listing .item .cols .label {
    display: none;
  }
}
.app-body.page-saved-search-listing .item .cols .search-name {
  font-size: 16px;
  line-height: 16px;
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .item .cols .search-name {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
  }
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .item .cols .mobile-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    line-height: 14px;
  }
  .app-body.page-saved-search-listing .item .cols .mobile-row .label {
    font-weight: 600;
  }
}
.app-body.page-saved-search-listing .item .actions {
  min-width: 223px;
}
@media (min-width: 430px) {
  .app-body.page-saved-search-listing .item .actions {
    display: flex;
    justify-content: flex-end;
    padding-right: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  }
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .item .actions {
    width: 100%;
  }
}
.app-body.page-saved-search-listing .item .actions .btn {
  display: block;
  font-size: 14px;
  line-height: 16px;
}
@media (min-width: 430px) {
  .app-body.page-saved-search-listing .item .actions .btn {
    width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  }
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .item .actions .btn {
    display: block;
  }
}
.app-body.page-saved-search-listing .paging {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 32px;
}
.app-body.page-saved-search-listing .paging .btn {
  width: 62px;
}
.app-body.page-saved-search-listing .details .backlink {
  display: block;
  padding-left: 28px;
  background: url('../public/icons/arrow-left.svg') no-repeat left center;
  margin: 32px 0 24px 0;
}
@media (min-width: 430px) {
  .app-body.page-saved-search-listing .details .backlink {
    width: fit-content;
    font-size: 12px;
    line-height: 18px;
  }
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .details .backlink {
    margin: 27px 0;
  }
}
.app-body.page-saved-search-listing .details .title {
  padding: 0 0 32px 0;
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
}
@media (min-width: 430px) {
  .app-body.page-saved-search-listing .details .title {
    border-bottom: 0.5px solid #000000;
  }
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .details .title {
    font-size: 32px;
    line-height: 32px;
  }
}
.app-body.page-saved-search-listing .details .tabs {
  border-bottom: 1px solid #348cf9;
  display: flex;
  align-items: flex-start;
  gap: 22px;
  margin-bottom: 20px;
  overflow: auto;
}
@media (min-width: 430px) {
  .app-body.page-saved-search-listing .details .tabs {
    display: none;
  }
}
.app-body.page-saved-search-listing .details .tabs .tab {
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  width: 100%;
  white-space: nowrap;
}
.app-body.page-saved-search-listing .details .tabs .tab.active {
  background-color: #348cf9;
  color: #ffffff;
  font-weight: 500;
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .details section.mobile-hidden {
    display: none;
  }
}
.app-body.page-saved-search-listing .details section.mandatory-fields .section-title {
  color: #348cf9;
}
.app-body.page-saved-search-listing .details section.optional-fields .section-title {
  margin-bottom: 0;
}
.app-body.page-saved-search-listing .details section .section-title {
  font-size: 20px;
  line-height: 16px;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 24px;
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .details section .section-title {
    display: none;
  }
}
.app-body.page-saved-search-listing .details section .group-title {
  font-size: 18px;
  line-height: 16px;
  font-weight: 500;
  color: #348cf9;
  padding: 20px 0;
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .details section .group-title {
    font-size: 16px;
  }
}
.app-body.page-saved-search-listing .details section .group {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 0.5px solid #adb5bd;
  gap: 80px;
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .details section .group {
    flex-direction: column;
    gap: 16px;
  }
}
@media (min-width: 430px) {
  .app-body.page-saved-search-listing .details section .group.mobile-only {
    display: none;
  }
}
.app-body.page-saved-search-listing .details section .group .field {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 3 + 20px * 2);
  display: flex;
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .details section .group .field {
    width: 100%;
    justify-content: space-between;
  }
}
.app-body.page-saved-search-listing .details section .group .field .label {
  height: 24px;
  padding-left: 32px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  background: url('../public/icons/justify.svg') no-repeat left top;
}
@media (min-width: 430px) {
  .app-body.page-saved-search-listing .details section .group .field .label {
    min-width: 190px;
    flex: 0 1 190px;
    height: fit-content;
  }
}
@media (max-width: 429px) {
  .app-body.page-saved-search-listing .details section .group .field .value {
    width: 129px;
  }
}
.pagination-container {
  display: flex;
  flex-direction: row;
  color: whitesmoke;
  font-size: 20px;
}
.pagination-container .page {
  cursor: pointer;
  background-color: red;
}
.pagination-container .page.selected {
  cursor: default;
}
.pagination-container .next,
.pagination-container .prev {
  cursor: pointer;
  background-color: green;
}
.pagination-container .next.disabled,
.pagination-container .prev.disabled {
  cursor: not-allowed;
}
.pagination-container .break {
  cursor: pointer;
  background-color: blue;
}
.error-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.error-wrapper .general-error {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 48px calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  background-color: #f4f5f7;
  border-radius: 9px;
  text-align: center;
  gap: 24px;
}
.error-wrapper .general-error .big-text {
  font-size: 20px;
  line-height: 22px;
}
.error-wrapper .general-error .small-text {
  font-size: 16px;
  line-height: 14px;
}
.error-wrapper .general-error .actions {
  color: #348cf9;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  font-size: 12px;
  line-height: 18px;
}
@media (max-width: 429px) {
  .error-wrapper .general-error .actions {
    gap: 24px;
  }
}
.error-wrapper .general-error .actions .backlink {
  padding-left: 32px;
  background: url('../public/icons/arrow-left-blue.svg') no-repeat left center;
}
.error-wrapper .general-error .actions .refresh {
  padding-left: 24px;
  background: url('../public/icons/arrow-clockwise.svg') no-repeat left center;
}
.error-wrapper .general-error .actions .logout {
  padding-left: 24px;
  background: url('../public/icons/box-arrow-right-blue.svg') no-repeat left center;
}
